<template>
  <div class="line-maps-page">
    <div class="row">
      <div class="flex md12 xs12">
        <vac-card
          class="line-maps-page__widget"
          title="Line Maps"
        >
          <line-map
            :map-data="lineMapData"
            style="height: 65vh;"
          />
        </vac-card>
      </div>
    </div>
  </div>
</template>

<script>
import LineMap from './LineMap';
import { getLineMapData } from '../../../services/data/maps/LineMapData';

export default {
  name: 'line-maps-page',
  components: {
    LineMap
  },
  computed: {
    lineMapData () {
      return getLineMapData(this.$themes);
    },
  },
};
</script>
